import Trainee_Calendar from "./Trainee_Calendar";
import React from "react";



const TraineeExerciseSchedule = (props) => {

    return (
        <div className='trainee-exercise-schedule'>
            <Trainee_Calendar/>
        </div>
    )
}

export default TraineeExerciseSchedule;