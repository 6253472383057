
const dataSource = [{
    train_type: "קרוספיט",
    amount: 5
  },
  {
    train_type: "שחיה",
    amount: 10
  },
  {
    train_type: "ריצה",
    amount: 10
  },
  {
    train_type: "ריקוד",
    amount: 10
  }
];

export default {
  getPieData() {
    return dataSource;
  }
};