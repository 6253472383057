import Trainer_Calendar from "./Trainer_Calendar";
import React from "react";

const TrainerExerciseSchedule = (props) => {

    return (
        <div className='trainer-exercise-schedule'>
            <Trainer_Calendar/>
        </div>
    )
}

export default TrainerExerciseSchedule;