

const monthData = [{
    month: "דצמבר",
    training_amount: 5,
}, {
    month: "נובמבר",
    training_amount: 6,
}, {
    month: "אוקטובר",
    training_amount: 7,
}, {
    month: "ספטמבר",
    training_amount: 1,
}, {
    month: "אוגוסט",
    training_amount: 10,
}, {
    month: "יולי",
    training_amount: 2,
}, {
    month: "יוני",
    training_amount: 5,
}, {
    month: "מאי",
    training_amount: 6,
}, {
    month: "אפריל",
    training_amount: 11,
}, {
    month: "מרץ",
    training_amount: 4,
}, {
    month: "פבואר",
    training_amount: 5,
}, {
    month: "ינואר",
    training_amount: 5,

}];

export default {
  getMonthData() {
    return monthData;
  }
};